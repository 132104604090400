import { render, staticRenderFns } from "./CardTabsMobile.vue?vue&type=template&id=55b6e1bf&"
import script from "./CardTabsMobile.vue?vue&type=script&lang=ts&"
export * from "./CardTabsMobile.vue?vue&type=script&lang=ts&"
import style0 from "./CardTabsMobile.vue?vue&type=style&index=0&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IntersectionObserver: require('/var/www/src/components/IntersectionObserver/IntersectionObserver.vue').default,Tab: require('/var/www/src/components/Tab/Tab.vue').default,BaseImage: require('/var/www/src/components/BaseImage/BaseImage.vue').default,CardHeader: require('/var/www/src/components/CardHeader/CardHeader.vue').default,ReadMore: require('/var/www/src/components/ReadMore/ReadMore.vue').default,MicroLoader: require('/var/www/src/components/MicroLoader/MicroLoader.vue').default})
