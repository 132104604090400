import { defineComponent, useCssModule } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'Tab',
    props: {
        activeBold: {
            default: true,
            type: Boolean,
        },
        isActive: {
            default: false,
            type: Boolean,
        },
        to: {
            default: '',
            type: String,
        },
        theme: {
            default: 'white',
            type: String,
            validator(value) {
                return ['white', 'black', 'light', 'dark', 'yellow', 'lighter'].includes(value);
            },
        },
    },
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        return {
            css,
        };
    },
});
