// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".otQE6{display:inline-flex;align-items:center;padding:7px 15px;border:1px solid transparent;border-radius:4px;cursor:pointer;font-family:\"Source Sans Pro\",sans-serif;font-size:16px;font-weight:400;line-height:24px;outline:0;transition:background-color .2s,border-color .2s,color .2s,font-weight .2s;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.otQE6 b,.otQE6 strong{font-weight:600}.otQE6.RSV1L{border-color:#e2e2e2;background-color:#fff;color:#2d2d2d}.otQE6._9pxY9{border-color:#2d2d2d;background-color:#181716;color:#989898}.otQE6.lc5GH{border-color:transparent;background-color:#f4f4f4;color:#232323}.otQE6.lc5GH:active{-webkit-tap-highlight-color:rgba(0,0,0,0);background-color:#e2e2e2}@media(any-hover:hover){.otQE6.lc5GH:hover{background-color:#e2e2e2}}.otQE6.yKjm3{border-color:#e2e2e2;background-color:#e2e2e2;color:#2d2d2d}.otQE6.BCZ9z{border-color:#2d2d2d;background-color:#2d2d2d;color:#989898}.otQE6.WFmFS{border-color:#f4e600;background-color:#f4e600;color:#2d2d2d}.otQE6.WFmFS:active{-webkit-tap-highlight-color:rgba(0,0,0,0);background-color:#f5dc00}@media(any-hover:hover){.otQE6.WFmFS:hover{background-color:#f5dc00}}.otQE6.YHtE5.YTRY5{font-weight:600}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab": "otQE6",
	"white": "RSV1L",
	"black": "_9pxY9",
	"lighter": "lc5GH",
	"light": "yKjm3",
	"dark": "BCZ9z",
	"yellow": "WFmFS",
	"active": "YHtE5",
	"bold": "YTRY5"
};
module.exports = ___CSS_LOADER_EXPORT___;
