// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aBUIb{padding-bottom:32px;background:#fff;overflow-x:hidden}.rJXoS{padding:24px 8px 16px;margin:0;color:#2d2d2d;font-family:\"Merriweather\",serif;font-size:26px;font-weight:700;line-height:30px}@media screen and (min-width:400px){.rJXoS{padding:24px 16px 16px}}.OZ\\+44{display:flex;flex-wrap:wrap;padding:0;margin:0 0 16px;list-style:none}@media screen and (min-width:400px){.OZ\\+44{padding:0 8px}}.PeMFG{flex-shrink:0;margin-top:8px;margin-left:8px}.zya-L{display:block;width:calc(100% - 16px);height:50.6666666667vw;margin:0 8px;border-radius:8px 8px 0 0;cursor:pointer;-o-object-fit:cover;object-fit:cover}@media screen and (min-width:400px){.zya-L{width:calc(100% - 32px);margin:0 16px}}.CxNJV{padding:0;border:1px solid #e2e2e2;margin:0 8px;border-radius:0 0 8px 8px;list-style:none}@media screen and (min-width:400px){.CxNJV{margin:0 16px}}.lxBbw{position:relative;cursor:pointer}.lxBbw:after{position:absolute;bottom:0;left:16px;width:calc(100% - 32px);height:1px;background:#e2e2e2;content:\"\"}.lxBbw:last-child:after{content:none}.gu4NZ{padding:0 16px 16px;margin:0;color:#2d2d2d;font-size:16px;font-weight:400;line-height:22px}.gu4NZ>a{color:inherit}.lxBbw:first-child .gu4NZ{padding-bottom:24px;font-family:\"Merriweather\",serif;font-size:22px;font-style:normal;font-weight:700;line-height:28px}.YcJr5{padding:24px 16px}.gsIbt{position:relative;min-height:200px}.yBGoJ{position:absolute;top:0;left:0;display:flex;width:100%;height:100%;align-items:center;justify-content:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "aBUIb",
	"title": "rJXoS",
	"tabs": "OZ+44",
	"tabsItem": "PeMFG",
	"image": "zya-L",
	"list": "CxNJV",
	"item": "lxBbw",
	"description": "gu4NZ",
	"read": "YcJr5",
	"content": "gsIbt",
	"loader": "yBGoJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
